import { Pointer } from "./pointer";
import { Trickle } from "./trickle";

export class Ancestry {

	pointer: Pointer;
	parentTabid: string;
	parentNodeid: string;
	trickle_path: Trickle[];

	constructor(pointer: Pointer, parentTabid: string, parentNodeid: string, trickle_path: Trickle[] = []) {

		this.pointer 		= pointer;
		this.parentTabid	= parentTabid;
		this.parentNodeid	= parentNodeid;
		this.trickle_path 	= trickle_path;
	}
}
