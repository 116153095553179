import { Ancestry } from '../models/ancestry';
import { Injectable, PLATFORM_ID, Inject, inject } from '@angular/core';
import { Pointer, ListenerHash, Tab } from 'src/app/models';
import { SSSTabService } from './sss-tab.service';
import { Store } from '@ngrx/store';
import { RegisterListener, UnRegisterListener } from 'src/app/ngrx/actions/listener.actions';
import * as _ from 'lodash';
import { AppState } from 'src/app/ngrx/reducers';
import { isPlatformBrowser } from '@angular/common';
import { PushActions } from '../ngrx/actions/beacon.actions';
import * as merge from 'deepmerge';
import { SSSAncestryService } from './sss-ancestry.service';
import { Trickle } from '../models';
import { SSSAccountService } from './sss-account.service';


@Injectable({
  	providedIn: 'root'
})
export class SSSListenerService {

	private sssTabService = inject(SSSTabService);
	private sssAncestryService = inject(SSSAncestryService);
	private sssAccountService = inject(SSSAccountService);
	private store = inject(Store<AppState>);
	private platformId = inject(PLATFORM_ID);

	private blackList: string[] = [ "day", "stub" ]; // these cannot be registered as listener nor follower

	public register( ancestry: Ancestry ): void {

		if( !isPlatformBrowser(this.platformId) ) 	{ return; }

		if( this.blackList.indexOf(ancestry.pointer.currenttab) != -1 ) { return; }

		if( !this.sssTabService.isLeaf( ancestry.pointer ) ) { return; }

		this.store.dispatch(PushActions( { payload: RegisterListener( { payload: ancestry } ) } ) );
	}

	public unregister( { pointer }: Ancestry ): void {

		if( !isPlatformBrowser(this.platformId) ) 	{ return; }

		if( !this.sssTabService.isLeaf( pointer ) ) { return; }

		this.store.dispatch(PushActions( { payload: UnRegisterListener( { payload: pointer } ) } ) );
	}

	public comandeer( ancestry: Ancestry ): void {

		this.unregister(ancestry);

		ancestry = ancestry.parentTabid ? this.sssAncestryService.comandeerAncestry(merge.all( [ancestry]) as Ancestry) : ancestry;

		this.register(ancestry);
	}

	public updateTricklePath(path: Trickle[], tabHash: { [key: string]: Tab[] } ): Trickle[] {

		return path.reduce((accum, obj) => {

			const comandeered = this.sssTabService.comandeerId( this.sssAccountService.getUser()._id, obj.tabid );

			return tabHash[ comandeered ] ? [ { ...obj, tabid: comandeered } ] : [ ...accum, obj ];

		}, []);
	}

	public decorateWithTricklePath( listeners: ListenerHash, tabHash: { [key: string]: Tab[] } ): ListenerHash  {

		return Object.keys(listeners).reduce((aaa, nodeid, idx) => 

			Object.keys(listeners[nodeid]).reduce((bbb, namespace, idx) => 

				Object.keys(listeners[nodeid][namespace]).reduce((ccc, instance, idx) => 

					Object.keys(listeners[nodeid][namespace][instance]).reduce((ddd, tabid, idx) => {

							const input 	= listeners[nodeid][namespace][instance][tabid].trickle_path;
							const output	= this.updateTricklePath( input, tabHash );

							listeners[nodeid][namespace][instance][tabid].trickle_path = output;
							
							return listeners;
					}, {})
				, {})
			, {})
		, {});
	}

    private generateHash( tabid: string, ancestry: Ancestry, isListening: boolean ): ListenerHash {

        const nodeid            = ancestry.pointer._id;
        const namespacetail     = this.sssTabService.deriveNamespaceTail( tabid );
        const instance          = this.sssTabService.deriveInstanceFromTabid( tabid );
        const idx               = this.sssTabService.derivePagIdx( ancestry.pointer, tabid )
        const pag               = ancestry.pointer.pagination[ idx ];
        const ghoulghost        = pag.ghoul || pag.ghost;
        const suffix            = ghoulghost ? `:${ghoulghost}` : "";
        const datapoint         = `${isListening}:${ancestry.pointer.defaultchildrenstate}${suffix}`;
        const trickle_path      = ancestry.trickle_path;

		return { [ nodeid ]: { [ namespacetail ]: { [ instance ]: { [ tabid ]: { datapoint, trickle_path } } } } }
    }

    private isActiveListener( 	pointer: Pointer ): boolean { return pointer.urlnodelistener == true; }

    private isPassiveListener( 	pointer: Pointer ): boolean { return pointer.urlnodelistener == false; }

    private shouldListen( pointer: Pointer, idx: number ): boolean {

        return this.isActiveListener( pointer ) ||

             ( this.isPassiveListener( pointer ) && pointer.pagination[ idx ].serial == "primero" );
    }

    private shouldNotListen( pointer: Pointer ): boolean {

        return this.isPassiveListener( pointer ) && pointer.pagination[0].serial != "primero";
	}

    public isAlreadyAListener( tabid: string, listeners: ListenerHash ): boolean {

        const { nodeid, namespace } = this.sssTabService.interpretTabid( tabid ); // day namespace is not timeline namespace

        return Object.keys( ( _.get( listeners, `${nodeid}.${namespace}`) || {} ) ).length > 0;
	}

	public doesExactMatchExist(stateListener: ListenerHash, nodeid: string, namespace: string, instance: string, tabid: string): boolean {

		return Object.keys( ( _.get( stateListener, `${nodeid}.${namespace}.${instance}.${tabid}`) || {} ) ).length > 0;
	}

	generateListenerContribution(stateListener: ListenerHash, ancestry: Ancestry): ListenerHash {

		return ancestry.pointer.pagination.reduce((accum, pag, index) => {

			const tabid 		= this.sssTabService.deriveTabidFromPointer(ancestry.pointer, index);

			const {nodeid, namespace, instance} = this.sssTabService.interpretTabid(tabid);

			const shouldListen 	= this.shouldListen(ancestry.pointer, index);
			const isLeaf 		= this.sssTabService.isLeaf(ancestry.pointer);
			const already 		= this.isAlreadyAListener(tabid, stateListener);
			const match 		= this.doesExactMatchExist(stateListener, nodeid, namespace, instance, tabid);


			const listener 		= isLeaf && shouldListen;
			const follower 		= !shouldListen && isLeaf && already;

			return !match && listener || follower
				? merge.all( [ accum, this.generateHash(tabid, ancestry, listener) ] ) as ListenerHash
				: accum;

		}, {});
	}

	generateListenerCandidate(stateListener: ListenerHash, ancestry: Ancestry): ListenerHash {

		return ancestry.pointer.pagination.reduce((accum, pag, index) => {

			const tabid 		= this.sssTabService.deriveTabidFromPointer(ancestry.pointer, index);

			const {nodeid, namespace, instance} = this.sssTabService.interpretTabid(tabid);

			const shouldListen 	= this.shouldListen(ancestry.pointer, index);
			const isLeaf 		= this.sssTabService.isLeaf(ancestry.pointer);
			const already 		= this.isAlreadyAListener(tabid, stateListener);
			const match 		= this.doesExactMatchExist(stateListener, nodeid, namespace, instance, tabid);


			const listener 		= isLeaf && shouldListen;
			const follower 		= !shouldListen && isLeaf && already;

			return listener || follower
				? merge.all( [ accum, this.generateHash(tabid, ancestry, listener) ] ) as ListenerHash
				: accum;

		}, {});
	}

	compareListenerSlice(stateListener: ListenerHash, ancestry: Ancestry): boolean { // returns true if they are the same nested object

		const nodeid 	= ancestry.pointer._id;
		const namespace = this.sssTabService.decipherTailNameSpaceByPointer(ancestry.pointer);
		const candidate = this.generateListenerCandidate(stateListener, ancestry);

		return 	!_.isEmpty(stateListener 	&& stateListener[nodeid]  	&& stateListener[nodeid][namespace])	&&
				!_.isEmpty(candidate 		&& candidate[nodeid]		&& candidate[nodeid][namespace])		&&
				 _.isEqual(stateListener[nodeid][namespace], candidate[nodeid][namespace]);
	}

	pushListenerHash(stateListener: ListenerHash, ancestry: Ancestry): ListenerHash {

		const contribution = this.generateListenerContribution(stateListener, ancestry);

		return merge.all( [ stateListener, contribution ] ) as ListenerHash
	}

	listenerExists(nameSpaceHash): boolean {

		for(let instancekey in nameSpaceHash) {

			for(let key in nameSpaceHash[ instancekey ]) {

				if( nameSpaceHash[ instancekey ][ key ].datapoint.split(':')[0] == 'true' ) {

					return true;
				}
			}
		}

		return false;
	}

	popListener( listeners: ListenerHash, pointer ): ListenerHash {

		return pointer.pagination.reduce((accum, pag, idx) => {

			const tabid 	= this.sssTabService.deriveTabidFromPointer(pointer, idx);
			const nodeid 	= pointer._id;
			const namespace = tabid.split("_").slice(-3)[0];
			const instance  = tabid.split("_").slice(-1)[0];

			if( _.get( accum, `${nodeid}.${namespace}.${instance}` ) ) {

				delete accum[ nodeid ][ namespace ][instance][ tabid ];
			}

			if( !this.listenerExists( _.get( accum, `${nodeid}.${namespace}` ) ) ) {

				accum = _.omit( listeners, nodeid );
			}

			return accum;

		}, merge.all([listeners]) as ListenerHash);
	}

	determineIfAlreadyListening( pointer: Pointer, listeners: ListenerHash): boolean {

		return pointer.pagination.reduce((accum, el, idx) => {

			const tabid = this.sssTabService.deriveTabidFromPointer(pointer, idx);

			const { nodeid, namespace, instance } = this.sssTabService.interpretTabid( tabid );

			return Object.keys( ( _.get( listeners, `${nodeid}.${namespace}.${instance}.${tabid}`) || {} ) ).length > 0;

		}, false);
	}
}
