import { SubstituteAppNode } from './../actions/application.actions';
import {
	LoadFetched as LoadFetchedNodes,
	LoadNode,
	ReloadNode,
	SubstituteNode,
	RemoveNode,
	UpdateNode} from './../actions/node.actions';
import { Action, createReducer, on } from '@ngrx/store';
import {
	replacePointer,
	replaceTab,
	comandeerNode,
	comandeerTab,
	comandeerNodeId,
	removeFromListener,
	AddToListenerHash,
	updateNode,
	updatePointer,
	loadNodes,
	loadTabs,
	removeNode,
	removeTab} from './helper';
import {
	ComandeerTab,
	LoadFetched as LoadFetchedTabs,
	LoadTab,
	RegisterDroppable,
	ReloadTab,
	RemoveTab,
	SetLastClicked,
	SubstitutePointer,
	SubstituteTab,
	UpdatePointer } from '../actions/tab.actions';
import { RegisterListener, UnRegisterListener, UpdateCurrentUrl } from '../actions/listener.actions';
import { SetApplicationNodeID } from '../actions/application.actions';
import * as _ from 'lodash';
import { ListenerHash, Node, Tab, User, Ancestry } from 'src/app/models';
import { LoadUserObj } from '../actions/auth.actions';


export const featureKey = 'graph';

export interface GraphState {
    appNodeID 	: string;
    nodes 		: { [key: string]: Node[] };
	tabs 		: { [key: string]: Tab[] };
	user 		: User;
	listeners   : ListenerHash;
	url	 		: string;
	click		: Ancestry;
	drop		: string[];
}
export const initialState: GraphState = {
    appNodeID 	: null,
    nodes 		: {},
	tabs		: {},
	user 		: new User( "GUEST" ),
	listeners   : {},
	url 		: '',
	click		: null,
	drop		: []
};
const graphReducer = createReducer(
	initialState,

	on(SetApplicationNodeID, 	(state, { payload }) 	=> ({ ...state, appNodeID: payload })),
	on(SubstituteAppNode, 		(state 			   ) 	=> ({ ...state, appNodeID: comandeerNodeId( state.user._id, state.appNodeID ),
																	 	nodes: comandeerNode( state.user, state.nodes, state.appNodeID) })),



    on(LoadNode,           		(state, { payload }) 	=> ({ ...state, nodes: 	{ ...state.nodes, [ payload.id ] : [ payload.node ] } })),
    on(ReloadNode,          	(state, { payload }) 	=> ({ ...state, nodes: 	{ ...state.nodes, [ payload ] : _.cloneDeep( state.nodes[ payload ] ) } })),
    on(LoadFetchedNodes,    	(state, { payload }) 	=> ({ ...state, nodes: 	loadNodes( state.nodes, payload ) } )),
    on(SubstituteNode, 			(state, { payload }) 	=> ({ ...state, nodes: 	comandeerNode( state.user, state.nodes, payload) })),
    on(UpdateNode, 				(state, { payload }) 	=> ({ ...state, nodes: 	updateNode( state.nodes, payload) })),
	on(RemoveNode,     			(state, { payload }) 	=> ({ ...state, nodes: 	removeNode( state.nodes, payload) })),

    on(LoadTab,           		(state, { payload }) 	=> ({ ...state, tabs: 	{ ...state.tabs, [ payload.id ] : [ payload.tab ] } })),
    on(ReloadTab,          		(state, { payload }) 	=> ({ ...state, tabs: 	{ ...state.tabs, [ payload ] : _.cloneDeep( state.tabs[ payload ] ) } })),
    on(ComandeerTab, 			(state, { payload }) 	=> ({ ...state, tabs:  	comandeerTab(	state.user, state.tabs, payload) })),
	on(LoadFetchedTabs,     	(state, { payload }) 	=> ({ ...state, tabs:  	loadTabs( state.tabs, payload ) })),
	on(RemoveTab,     			(state, { payload }) 	=> ({ ...state, tabs:  	removeTab( state.tabs, payload ) })),
	on(UpdatePointer,			(state, { payload }) 	=> ({ ...state, tabs:  	updatePointer(	state.tabs, payload)})),
	on(SubstituteTab, 			(state, { payload }) 	=> ({ ...state, tabs:  	replaceTab(state.tabs, payload) })),
    on(RegisterDroppable, 		(state, { payload }) 	=> ({ ...state, drop:  	payload.state ? [ ...state.drop, payload.tabid ] : state.drop.filter(tabid => tabid != payload.tabid) })),
	on(SetLastClicked,			(state, { payload }) 	=> ({ ...state, tabs:  	updatePointer(	state.tabs, { ancestry: payload, changes: { loading: true } }),
																		click: 	payload })),
	on(SubstitutePointer, 		(state, { payload }) 	=> ({ ...state, tabs:  	replacePointer(	state.user, state.tabs, payload),
																	 	nodes: 	comandeerNode(	state.user, state.nodes, payload.pointer._id)})),

	on(RegisterListener, 		(state, { payload }) 	=> ({ ...state, listeners: AddToListenerHash(state.listeners, payload) })),
	on(UnRegisterListener, 		(state, { payload }) 	=> ({ ...state, listeners: removeFromListener(state.listeners, payload) })),
	on(UpdateCurrentUrl, 		(state, { payload }) 	=> ({ ...state, url: payload })),

    on(LoadUserObj,				(state, { payload } ) 	=> ({ ...state, user: payload }))
);
export function reducer(state: GraphState | undefined, action: Action) {
  	return graphReducer(state, action);
}
