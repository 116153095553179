import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InsertData, MoveData, Pointer, DeleteData } from '../../models';
import { Action } from '@ngrx/store';
import { channelUrl } from 'src/app/helpers/urls';
import { InjectData } from 'src/app/models/injectData';
import { SSSMutateService } from './sss-mutate.interface';
import { Trickle } from 'src/app/models/trickle';

@Injectable({
  providedIn: 'root'
})
export class SSSMutateAuthService implements SSSMutateService {

	private http = inject(HttpClient)

	triggerInject(child_pointer: Pointer, idx: number, pagination_index: number, revision: number, trickle_path: Trickle[], parent_pointer: Pointer): Observable<{ actions: Action[] }> {

		const payload = new InjectData(child_pointer, idx); 

		return this.http.post<{ actions: Action[] }>( `${channelUrl}/INJECT`, { revision, payload, parent_pointer, pagination_index, trickle_path } );
	}

	triggerInsert(name: string, pagination_index: number, revision: number, trickle_path: Trickle[], parent_pointer: Pointer): Observable<{ actions: Action[] }> {

		const payload = new InsertData(name, name.replace(/\s+/g, '-').toLowerCase(), 0);

		return this.http.post<{ actions: Action[] }>( `${channelUrl}/INSERT`, { revision, payload, parent_pointer, pagination_index, trickle_path } );
	}

	triggerDelete(child_pointer: Pointer, idx: number, pagination_index: number, revision: number, trickle_path: Trickle[], parent_pointer: Pointer): Observable<{ actions: Action[] }> {

		const payload = new DeleteData(child_pointer, idx);

		return this.http.post<{ actions: Action[] }>( `${channelUrl}/DELETE`, { revision, payload, parent_pointer, pagination_index, trickle_path } );
	}

	triggerMove(from: number, to: number, pagination_index: number, revision: number, from_trickle_path: Trickle[], parent_pointer: Pointer, to_tab_id ?: string, to_trickle_path ?: Trickle[]): Observable<{ actions: Action[] }> {

		const payload = new MoveData(from, to, to_tab_id, to_trickle_path);

		return this.http.post<{ actions: Action[] }>( `${channelUrl}/MOVE`, { revision, payload, parent_pointer, pagination_index, from_trickle_path } );
	}

	triggerUpdate( payload: Pointer, pagination_index: number, revision: number, trickle_path: Trickle[], parent_pointer: Pointer ): Observable<{ actions: Action[] }> {

		return this.http.post<{ actions: Action[] }>( `${channelUrl}/UPDATE`, { revision, payload, parent_pointer, pagination_index, trickle_path } );
	}
}
