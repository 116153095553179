import { Trickle } from "./trickle";

export class MoveData {
    from                 : number;
	to                   : number;
	clone                : boolean;
    to_tab_id           ?: string;
    to_trickle_path     ?: Trickle[];

    constructor(from : number, to : number, to_tab_id ?: string, to_trickle_path ?: Trickle[]) {

        this.from 	            = from;
		this.to   	            = to;
		this.clone	            = false;
        this.to_tab_id          = to_tab_id
        this.to_trickle_path    = to_trickle_path
    }
}
