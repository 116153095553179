import { Injectable } from '@angular/core';
import { User, Node } from '../models';
import * as merge from 'deepmerge';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SSSNodeService {

  	constructor() { }

	comandeerId = ( username: string, old_id: string ) : string => {

		const splitarray = old_id.split("_");

		if( splitarray[0] == username ) { return old_id; }

		splitarray.length < 2 	? splitarray.unshift( username )
								: splitarray.splice( 0, 2, username, `${splitarray[0]}@${splitarray[1]}` );

		return splitarray.join("_");
	}

	comandeerNode(user: User, nodes: { [key: string]: Node[] }, oldNodeId: string ) : { [key: string]: Node[] } {

		if(!nodes[ oldNodeId ]) { return nodes; }

		const newNodeObj = { ...nodes[ oldNodeId ][0], _id: this.comandeerId( user._id, oldNodeId ) };

		return this.replaceNode(nodes, { oldNodeId, newNodeObj })
	}

	replaceNode( nodes: { [key: string]: Node[] }, { oldNodeId, newNodeObj } ) : { [key: string]: Node[] } {

		return { ...nodes, [ newNodeObj._id ] : [ newNodeObj ], [ oldNodeId ] : [ newNodeObj ] };
	}

	updateNode( nodes: { [key: string]: Node[] }, { nodeid, changes } ) : { [key: string]: Node[] } {

		return { ...nodes, [ nodeid ] : [ merge.all( [ nodes[ nodeid ][0], changes ] ) ] };
	}

	loadNodes( nodes: { [key: string]: Node[] }, incoming: { [key: string] : Node } ) : { [key: string]: Node[] } {

		return merge.all( [ incoming, nodes ] ) as { [key: string]: Node[] };
	}

	removeNode( nodes: { [key: string]: Node[] }, outgoingId: string ) : { [key: string]: Node[] } {

		switch(true) {
			case !nodes[ outgoingId ]				: return nodes;
			case nodes[ outgoingId ].length > 1		: return { ...nodes, [ outgoingId ]: nodes[ outgoingId ].slice(0, 1) };
			default									: return _.omit( nodes, outgoingId );
		}
	}
}
