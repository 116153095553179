import { SSSListenerService } from './sss-listener.service';
import { Ancestry } from '../models/ancestry';
import { Injectable, inject } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import { AppState } from '../ngrx/reducers';
import { getListeners, getNodeById, getTabById } from '../ngrx/selectors/graph.selector';
import { Subject, Observable } from 'rxjs';
import { takeUntil, filter, switchMap, withLatestFrom, map } from 'rxjs/operators';
import { PushActions } from '../ngrx/actions/beacon.actions';
import { RemoveNode } from '../ngrx/actions/node.actions';
import { SSSTabService } from './sss-tab.service';
import { Tab, Node, Pointer, Command } from '../models';
import * as _ from 'lodash';
import { SSSAccountService } from './sss-account.service';
import { getChangeByNodeId, getChangeByTabId, getTempChangeByTabId } from '../ngrx/selectors/change.selector';
import { SSSChangeService } from './sss-change.service';
import { ChangeWrapper } from '../models/change-wrapper';
import { Subscription } from '../models/subscription';
import { SSSLocalService } from './sss-local.service';
import { SSSAncestryService } from './sss-ancestry.service';

@Injectable({
  providedIn: 'root'
})
export class SSSSelectorService {

	public nodeHash: { [key: string]: number } = {};

	public sisterTabHash: { [key: string]: number } = {};

	private store = inject(Store<AppState>);
	private sssAccountService = inject(SSSAccountService);
	private sssChangeService = inject(SSSChangeService);
	private sssAncestryService = inject(SSSAncestryService);
	private sssListenerService = inject(SSSListenerService);
	private sssLocalService = inject(SSSLocalService);
	private sssTabService = inject(SSSTabService);

	public generateNodeSubscriptionObj(id: string, ancestry: Ancestry): Subscription { return new Subscription ( id, ancestry ); }

	public generateTabSubscriptionObj(id: string, ancestry: Ancestry): Subscription { return new Subscription ( id, ancestry ); }

	private getComandeeredFromHash(nodeid: string): string {

		const username = this.sssAccountService.getUser()._id;

		const comandeered = this.sssTabService.comandeerId( username, nodeid )

		return this.nodeHash[ comandeered ] && comandeered;
	}

	public registerNode(subscription: { id: string }, ancestry: Ancestry, _destroy$: Subject<boolean>): Observable<Node> {

		const alreadyComandeered 	= this.getComandeeredFromHash( ancestry.pointer._id );
		const pointer 				= {...ancestry.pointer, name: alreadyComandeered || ancestry.pointer._id };
		subscription.id  			= alreadyComandeered || ancestry.pointer._id;
		// urlPath use case may have comandeered a nodeid before
		// other sibling components in the tree first instantiate

		this.addToList(subscription.id, "nodeHash");

		this.addSistersToList(ancestry);

		return this.generateNodeSelector(subscription, pointer, _destroy$);
	}

	public registerTab(subscription: { id: string }, _destroy$: Subject<boolean>): Observable<Tab> {

		return this.generateTabSelector(subscription, _destroy$);
	}

	public comandeerNode(subscription: Subscription, oldId: string, newId: string, ancestry : Ancestry): void {

		this.addToList(newId, "nodeHash");

		this.addSistersToList( this.sssAncestryService.comandeerAncestry(ancestry) );

		subscription.id = newId;

		subscription.ancestry = ancestry;

		this.removeFromList(oldId, "nodeHash");

		this.removeSistersFromList( ancestry );
	}

	public comandeerTab(subscription: Subscription, newTabid: string, ancestry: Ancestry): void {

		const oldTabId = subscription.id;

		subscription.id = newTabid

		subscription.ancestry = ancestry;
	}

	public unregisterNode(_destroy$: Subject<boolean>, id: string) {

        _destroy$.next(null);
		_destroy$.complete();
		
		// this.removeFromList(id, "nodeHash");
	}

	public unregisterTab(id: string) {
		
		this.removeFromList(id, "sisterTabHash");
	}

	public selectNodeChanges(subscription: Subscription, _destroy$: Subject<boolean>): Observable<Action[]> {

		return this.store.pipe(

			takeUntil(_destroy$),
			select(getChangeByNodeId(subscription)),
			filter(		(command: Command) => !!command),
			filter(		(command: Command) => !!this.sssTabService.isLeaf( subscription.ancestry.pointer )),
			map( 	 	(command: Command) => ({ command, candidateTabid: this.sssTabService.deriveTabidFromPointer(subscription.ancestry.pointer, 0) })),
			filter(		({command, candidateTabid}) => !!this.sssTabService.isSimilarInstance( candidateTabid, command.tabid )),
			switchMap(	({command, candidateTabid}) => this.sssChangeService.fetchChangeActions( candidateTabid, command, subscription.ancestry))
		)
	}

	public selectTabChanges(subscription: Subscription, pagidx: number, _destroy$: Subject<boolean>): Observable<Action[]> {

		return this.store.pipe(

			takeUntil(_destroy$),
			select(getChangeByTabId(subscription)),
			filter((changes: ChangeWrapper) => !!changes ),
			switchMap((changes: ChangeWrapper) => this.sssChangeService.processChanges( subscription.ancestry, pagidx, changes ))
		)
	}

	public selectTempChanges(subscription: Subscription, _destroy$: Subject<boolean>): Observable<Command> {

		return this.store.pipe(

			takeUntil(_destroy$),
			select(getTempChangeByTabId(subscription)),
			filter((changes: Command) => !!changes )
		)
	}

	private addSistersToList(ancestry: Ancestry): void {

		if( this.sssTabService.isLeaf( ancestry.pointer ) ) { 
			ancestry.pointer.pagination.forEach((pad, idx) => {
				const tabid = this.sssTabService.deriveTabidFromPointer( ancestry.pointer, idx );
				this.addToList( this.sssTabService.deriveSisterHood( tabid ), "sisterTabHash"); 
			});
		}
	}

	private removeSistersFromList(ancestry: Ancestry): void {

		if( this.sssTabService.isLeaf( ancestry.pointer ) ) { 
			ancestry.pointer.pagination.forEach((pad, idx) => {
				const tabid = this.sssTabService.deriveTabidFromPointer( ancestry.pointer, idx );
				this.removeFromList( this.sssTabService.deriveSisterHood( tabid ), "sisterTabHash"); 
			});
		}
	}

	private addToList( _id: string, hash: string ) {

		this[ hash ][ _id ] = this[ hash ][ _id ] ? ++this[ hash ][ _id ] : 1;
	}

	private removeFromList(id: string, hash: string): void {

		if( this[ hash ][ id ] > 1 ) {

			--this[ hash ][ id ];

		} else {

			delete this[ hash ][ id ];

			this.store.dispatch(PushActions( { payload: RemoveNode( { payload: id } ) } ) );

			this.sssLocalService.removeObjByKey( id );
		}
	}

	private generateNodeSelector(subscription: { id: string }, pointer: Pointer, _destroy$: Subject<boolean>): Observable<Node> {

		return this.store.pipe(
			takeUntil(_destroy$),
			select(getNodeById(subscription)),
			withLatestFrom(this.store.select(getListeners)),
			filter(([node, listeners]) => {

				const alreadyListening = this.sssListenerService.determineIfAlreadyListening( pointer, listeners );

				const noIdChange = node && pointer._id == node._id;

				return !(noIdChange && alreadyListening);
			}),

			map(([node, listeners]) => node )
		);
	}

	private generateTabSelector(subscription: { id: string }, _destroy$: Subject<boolean>): Observable<Tab> {

		return this.store.pipe(
			takeUntil(_destroy$),
			select(getTabById(subscription))
		);
	}
}
