import { Pointer } from ".";

export class DeleteData {
    pointer: Pointer;
	idx: number;

    constructor(pointer: Pointer, idx: number) {

        this.pointer = pointer;
		this.idx = idx;
    }
}
