export const statusOptions: string[] = [
	"anon",
	"auth"
];

export const aproachOptions: string[] = [
	"first/urlpaths",
	"following/clicks",
	"following/urlpaths"
];

export const ingrediantsOptions: string[] = [
	"postmix",
	"postsolo",
	"primix",
	"prisolo"
]

export const testOptions = {
	"anon" : {
		"first/urlpaths" : {
			"postmix": [
				"contribute1to1ghost1ghost1day1day",
				"contribute1to1ghost1ghost1fold1fold",
				"contribute1to1ghost1ghost1minotaur1minotaur",
				"contribute1to1ghost1ghost1timeline1timeline",
				"contribute1to1ghost1ghoul1day1day",
				"contribute1to1ghost1ghoul1fold1fold",
				"contribute1to1ghost1ghoul1minotaur1minotaur",
				"contribute1to1ghost1ghoul1timeline1timeline",
				"contribute1to1ghost1pers1day1day",
				"contribute1to1ghost1pers1fold1fold",
				"contribute1to1ghost1pers1minotaur1minotaur",
				"contribute1to1ghost1pers1timeline1timeline",
				"contribute1to1ghoul1ghost1day1day",
				"contribute1to1ghoul1ghost1fold1fold",
				"contribute1to1ghoul1ghost1minotaur1minotaur",
				"contribute1to1ghoul1ghost1timeline1timeline",
				"contribute1to1ghoul1ghoul1day1day",
				"contribute1to1ghoul1ghoul1fold1fold",
				"contribute1to1ghoul1ghoul1minotaur1minotaur",
				"contribute1to1ghoul1ghoul1timeline1timeline",
				"contribute1to1ghoul1pers1day1day",
				"contribute1to1ghoul1pers1fold1fold",
				"contribute1to1ghoul1pers1minotaur1minotaur",
				"contribute1to1ghoul1pers1timeline1timeline",
				"contribute1to1pers1ghost1day1day",
				"contribute1to1pers1ghost1fold1fold",
				"contribute1to1pers1ghost1minotaur1minotaur",
				"contribute1to1pers1ghost1timeline1timeline",
				"contribute1to1pers1ghoul1day1day",
				"contribute1to1pers1ghoul1fold1fold",
				"contribute1to1pers1ghoul1minotaur1minotaur",
				"contribute1to1pers1ghoul1timeline1timeline",
				"contribute1to1pers1pers1day1day",
				"contribute1to1pers1pers1fold1fold",
				"contribute1to1pers1pers1minotaur1minotaur",
				"contribute1to1pers1pers1timeline1timeline"
			],
			"postsolo": [
				"contribute1to1ghost1day1day",
				"contribute1to1ghost1fold1fold",
				"contribute1to1ghost1minotaur1minotaur",
				"contribute1to1ghost1timeline1timeline",
				"contribute1to1ghoul1day1day",
				"contribute1to1ghoul1fold1fold",
				"contribute1to1ghoul1minotaur1minotaur",
				"contribute1to1ghoul1timeline1timeline",
				"contribute1to1pers1day1day",
				"contribute1to1pers1fold1fold",
				"contribute1to1pers1minotaur1minotaur",
				"contribute1to1pers1timeline1timeline"
			],
			"primix": [
				"contribute1to1ghost1ghost1day1day",
				"contribute1to1ghost1ghost1fold1fold",
				"contribute1to1ghost1ghost1minotaur1minotaur",
				"contribute1to1ghost1ghost1timeline1timeline",
				"contribute1to1ghost1ghoul1day1day",
				"contribute1to1ghost1ghoul1fold1fold",
				"contribute1to1ghost1ghoul1minotaur1minotaur",
				"contribute1to1ghost1ghoul1timeline1timeline",
				"contribute1to1ghost1pers1day1day",
				"contribute1to1ghost1pers1fold1fold",
				"contribute1to1ghost1pers1minotaur1minotaur",
				"contribute1to1ghost1pers1timeline1timeline",
				"contribute1to1ghoul1ghost1day1day",
				"contribute1to1ghoul1ghost1fold1fold",
				"contribute1to1ghoul1ghost1minotaur1minotaur",
				"contribute1to1ghoul1ghost1timeline1timeline",
				"contribute1to1ghoul1ghoul1day1day",
				"contribute1to1ghoul1ghoul1fold1fold",
				"contribute1to1ghoul1ghoul1minotaur1minotaur",
				"contribute1to1ghoul1ghoul1timeline1timeline",
				"contribute1to1ghoul1pers1day1day",
				"contribute1to1ghoul1pers1fold1fold",
				"contribute1to1ghoul1pers1minotaur1minotaur",
				"contribute1to1ghoul1pers1timeline1timeline",
				"contribute1to1pers1ghost1day1day",
				"contribute1to1pers1ghost1fold1fold",
				"contribute1to1pers1ghost1minotaur1minotaur",
				"contribute1to1pers1ghost1timeline1timeline",
				"contribute1to1pers1ghoul1day1day",
				"contribute1to1pers1ghoul1fold1fold",
				"contribute1to1pers1ghoul1minotaur1minotaur",
				"contribute1to1pers1ghoul1timeline1timeline",
				"contribute1to1pers1pers1day1day",
				"contribute1to1pers1pers1fold1fold",
				"contribute1to1pers1pers1minotaur1minotaur",
				"contribute1to1pers1pers1timeline1timeline"
			],
			"prisolo": [
				"contribute1to1ghost1day1day",
				"contribute1to1ghost1fold1fold",
				"contribute1to1ghost1minotaur1minotaur",
				"contribute1to1ghost1timeline1timeline",
				"contribute1to1ghoul1day1day",
				"contribute1to1ghoul1fold1fold",
				"contribute1to1ghoul1minotaur1minotaur",
				"contribute1to1ghoul1timeline1timeline",
				"contribute1to1pers1day1day",
				"contribute1to1pers1fold1fold",
				"contribute1to1pers1minotaur1minotaur",
				"contribute1to1pers1timeline1timeline"
			]
		},
		"following/clicks" : {
			"postmix": [
				"contribute1to1ghost1ghost1day1day",
				"contribute1to1ghost1ghost1fold1fold",
				"contribute1to1ghost1ghost1minotaur1minotaur",
				"contribute1to1ghost1ghost1timeline1timeline",
				"contribute1to1ghost1ghoul1day1day",
				"contribute1to1ghost1ghoul1fold1fold",
				"contribute1to1ghost1ghoul1minotaur1minotaur",
				"contribute1to1ghost1ghoul1timeline1timeline",
				"contribute1to1ghost1pers1day1day",
				"contribute1to1ghost1pers1fold1fold",
				"contribute1to1ghost1pers1minotaur1minotaur",
				"contribute1to1ghost1pers1timeline1timeline",
				"contribute1to1ghoul1ghost1day1day",
				"contribute1to1ghoul1ghost1fold1fold",
				"contribute1to1ghoul1ghost1minotaur1minotaur",
				"contribute1to1ghoul1ghost1timeline1timeline",
				"contribute1to1ghoul1ghoul1day1day",
				"contribute1to1ghoul1ghoul1fold1fold",
				"contribute1to1ghoul1ghoul1minotaur1minotaur",
				"contribute1to1ghoul1ghoul1timeline1timeline",
				"contribute1to1ghoul1pers1day1day",
				"contribute1to1ghoul1pers1fold1fold",
				"contribute1to1ghoul1pers1minotaur1minotaur",
				"contribute1to1ghoul1pers1timeline1timeline",
				"contribute1to1pers1ghost1day1day",
				"contribute1to1pers1ghost1fold1fold",
				"contribute1to1pers1ghost1minotaur1minotaur",
				"contribute1to1pers1ghost1timeline1timeline",
				"contribute1to1pers1ghoul1day1day",
				"contribute1to1pers1ghoul1fold1fold",
				"contribute1to1pers1ghoul1minotaur1minotaur",
				"contribute1to1pers1ghoul1timeline1timeline",
				"contribute1to1pers1pers1day1day",
				"contribute1to1pers1pers1fold1fold",
				"contribute1to1pers1pers1minotaur1minotaur",
				"contribute1to1pers1pers1timeline1timeline"
			],
			"postsolo": [
				"contribute1to1ghost1day1day",
				"contribute1to1ghost1fold1fold",
				"contribute1to1ghost1minotaur1minotaur",
				"contribute1to1ghost1timeline1timeline",
				"contribute1to1ghoul1day1day",
				"contribute1to1ghoul1fold1fold",
				"contribute1to1ghoul1minotaur1minotaur",
				"contribute1to1ghoul1timeline1timeline",
				"contribute1to1pers1day1day",
				"contribute1to1pers1fold1fold",
				"contribute1to1pers1minotaur1minotaur",
				"contribute1to1pers1timeline1timeline"
			],
			"primix": [
				"contribute1to1ghost1ghost1day1day",
				"contribute1to1ghost1ghost1fold1fold",
				"contribute1to1ghost1ghost1minotaur1minotaur",
				"contribute1to1ghost1ghost1timeline1timeline",
				"contribute1to1ghost1ghoul1day1day",
				"contribute1to1ghost1ghoul1fold1fold",
				"contribute1to1ghost1ghoul1minotaur1minotaur",
				"contribute1to1ghost1ghoul1timeline1timeline",
				"contribute1to1ghost1pers1day1day",
				"contribute1to1ghost1pers1fold1fold",
				"contribute1to1ghost1pers1minotaur1minotaur",
				"contribute1to1ghost1pers1timeline1timeline",
				"contribute1to1ghoul1ghost1day1day",
				"contribute1to1ghoul1ghost1fold1fold",
				"contribute1to1ghoul1ghost1minotaur1minotaur",
				"contribute1to1ghoul1ghost1timeline1timeline",
				"contribute1to1ghoul1ghoul1day1day",
				"contribute1to1ghoul1ghoul1fold1fold",
				"contribute1to1ghoul1ghoul1minotaur1minotaur",
				"contribute1to1ghoul1ghoul1timeline1timeline",
				"contribute1to1ghoul1pers1day1day",
				"contribute1to1ghoul1pers1fold1fold",
				"contribute1to1ghoul1pers1minotaur1minotaur",
				"contribute1to1ghoul1pers1timeline1timeline",
				"contribute1to1pers1ghost1day1day",
				"contribute1to1pers1ghost1fold1fold",
				"contribute1to1pers1ghost1minotaur1minotaur",
				"contribute1to1pers1ghost1timeline1timeline",
				"contribute1to1pers1ghoul1day1day",
				"contribute1to1pers1ghoul1fold1fold",
				"contribute1to1pers1ghoul1minotaur1minotaur",
				"contribute1to1pers1ghoul1timeline1timeline",
				"contribute1to1pers1pers1day1day",
				"contribute1to1pers1pers1fold1fold",
				"contribute1to1pers1pers1minotaur1minotaur",
				"contribute1to1pers1pers1timeline1timeline"
			],
			"prisolo": [
				"contribute1to1ghost1day1day",
				"contribute1to1ghost1fold1fold",
				"contribute1to1ghost1minotaur1minotaur",
				"contribute1to1ghost1timeline1timeline",
				"contribute1to1ghoul1day1day",
				"contribute1to1ghoul1fold1fold",
				"contribute1to1ghoul1minotaur1minotaur",
				"contribute1to1ghoul1timeline1timeline",
				"contribute1to1pers1day1day",
				"contribute1to1pers1fold1fold",
				"contribute1to1pers1minotaur1minotaur",
				"contribute1to1pers1timeline1timeline"
			]
		},
		"following/urlpaths" : {
			"postmix": [
				"contribute1to1ghost1ghost1day1day",
				"contribute1to1ghost1ghost1fold1fold",
				"contribute1to1ghost1ghost1minotaur1minotaur",
				"contribute1to1ghost1ghost1timeline1timeline",
				"contribute1to1ghost1ghoul1day1day",
				"contribute1to1ghost1ghoul1fold1fold",
				"contribute1to1ghost1ghoul1minotaur1minotaur",
				"contribute1to1ghost1ghoul1timeline1timeline",
				"contribute1to1ghost1pers1day1day",
				"contribute1to1ghost1pers1fold1fold",
				"contribute1to1ghost1pers1minotaur1minotaur",
				"contribute1to1ghost1pers1timeline1timeline",
				"contribute1to1ghoul1ghost1day1day",
				"contribute1to1ghoul1ghost1fold1fold",
				"contribute1to1ghoul1ghost1minotaur1minotaur",
				"contribute1to1ghoul1ghost1timeline1timeline",
				"contribute1to1ghoul1ghoul1day1day",
				"contribute1to1ghoul1ghoul1fold1fold",
				"contribute1to1ghoul1ghoul1minotaur1minotaur",
				"contribute1to1ghoul1ghoul1timeline1timeline",
				"contribute1to1ghoul1pers1day1day",
				"contribute1to1ghoul1pers1fold1fold",
				"contribute1to1ghoul1pers1minotaur1minotaur",
				"contribute1to1ghoul1pers1timeline1timeline",
				"contribute1to1pers1ghost1day1day",
				"contribute1to1pers1ghost1fold1fold",
				"contribute1to1pers1ghost1minotaur1minotaur",
				"contribute1to1pers1ghost1timeline1timeline",
				"contribute1to1pers1ghoul1day1day",
				"contribute1to1pers1ghoul1fold1fold",
				"contribute1to1pers1ghoul1minotaur1minotaur",
				"contribute1to1pers1ghoul1timeline1timeline",
				"contribute1to1pers1pers1day1day",
				"contribute1to1pers1pers1fold1fold",
				"contribute1to1pers1pers1minotaur1minotaur",
				"contribute1to1pers1pers1timeline1timeline"
			],
			"postsolo": [
				"contribute1to1ghost1day1day",
				"contribute1to1ghost1fold1fold",
				"contribute1to1ghost1minotaur1minotaur",
				"contribute1to1ghost1timeline1timeline",
				"contribute1to1ghoul1day1day",
				"contribute1to1ghoul1fold1fold",
				"contribute1to1ghoul1minotaur1minotaur",
				"contribute1to1ghoul1timeline1timeline",
				"contribute1to1pers1day1day",
				"contribute1to1pers1fold1fold",
				"contribute1to1pers1minotaur1minotaur",
				"contribute1to1pers1timeline1timeline"
			],
			"primix": [
				"contribute1to1ghost1ghost1day1day",
				"contribute1to1ghost1ghost1fold1fold",
				"contribute1to1ghost1ghost1minotaur1minotaur",
				"contribute1to1ghost1ghost1timeline1timeline",
				"contribute1to1ghost1ghoul1day1day",
				"contribute1to1ghost1ghoul1fold1fold",
				"contribute1to1ghost1ghoul1minotaur1minotaur",
				"contribute1to1ghost1ghoul1timeline1timeline",
				"contribute1to1ghost1pers1day1day",
				"contribute1to1ghost1pers1fold1fold",
				"contribute1to1ghost1pers1minotaur1minotaur",
				"contribute1to1ghost1pers1timeline1timeline",
				"contribute1to1ghoul1ghost1day1day",
				"contribute1to1ghoul1ghost1fold1fold",
				"contribute1to1ghoul1ghost1minotaur1minotaur",
				"contribute1to1ghoul1ghost1timeline1timeline",
				"contribute1to1ghoul1ghoul1day1day",
				"contribute1to1ghoul1ghoul1fold1fold",
				"contribute1to1ghoul1ghoul1minotaur1minotaur",
				"contribute1to1ghoul1ghoul1timeline1timeline",
				"contribute1to1ghoul1pers1day1day",
				"contribute1to1ghoul1pers1fold1fold",
				"contribute1to1ghoul1pers1minotaur1minotaur",
				"contribute1to1ghoul1pers1timeline1timeline",
				"contribute1to1pers1ghost1day1day",
				"contribute1to1pers1ghost1fold1fold",
				"contribute1to1pers1ghost1minotaur1minotaur",
				"contribute1to1pers1ghost1timeline1timeline",
				"contribute1to1pers1ghoul1day1day",
				"contribute1to1pers1ghoul1fold1fold",
				"contribute1to1pers1ghoul1minotaur1minotaur",
				"contribute1to1pers1ghoul1timeline1timeline",
				"contribute1to1pers1pers1day1day",
				"contribute1to1pers1pers1fold1fold",
				"contribute1to1pers1pers1minotaur1minotaur",
				"contribute1to1pers1pers1timeline1timeline"
			],
			"prisolo": [
				"contribute1to1ghost1day1day",
				"contribute1to1ghost1fold1fold",
				"contribute1to1ghost1minotaur1minotaur",
				"contribute1to1ghost1timeline1timeline",
				"contribute1to1ghoul1day1day",
				"contribute1to1ghoul1fold1fold",
				"contribute1to1ghoul1minotaur1minotaur",
				"contribute1to1ghoul1timeline1timeline",
				"contribute1to1pers1day1day",
				"contribute1to1pers1fold1fold",
				"contribute1to1pers1minotaur1minotaur",
				"contribute1to1pers1timeline1timeline"
			]
		}
	},
	"auth" : {
		"following/clicks" : {
			"postmix": [
				"contribute1to1ghost1ghost1day1day",
				"contribute1to1ghost1ghost1fold1fold",
				"contribute1to1ghost1ghost1minotaur1minotaur",
				"contribute1to1ghost1ghost1timeline1timeline",
				"contribute1to1ghost1ghoul1day1day",
				"contribute1to1ghost1ghoul1fold1fold",
				"contribute1to1ghost1ghoul1minotaur1minotaur",
				"contribute1to1ghost1ghoul1timeline1timeline",
				"contribute1to1ghost1pers1day1day",
				"contribute1to1ghost1pers1fold1fold",
				"contribute1to1ghost1pers1minotaur1minotaur",
				"contribute1to1ghost1pers1timeline1timeline",
				"contribute1to1ghoul1ghost1day1day",
				"contribute1to1ghoul1ghost1fold1fold",
				"contribute1to1ghoul1ghost1minotaur1minotaur",
				"contribute1to1ghoul1ghost1timeline1timeline",
				"contribute1to1ghoul1ghoul1day1day",
				"contribute1to1ghoul1ghoul1fold1fold",
				"contribute1to1ghoul1ghoul1minotaur1minotaur",
				"contribute1to1ghoul1ghoul1timeline1timeline",
				"contribute1to1ghoul1pers1day1day",
				"contribute1to1ghoul1pers1fold1fold",
				"contribute1to1ghoul1pers1minotaur1minotaur",
				"contribute1to1ghoul1pers1timeline1timeline",
				"contribute1to1pers1ghost1day1day",
				"contribute1to1pers1ghost1fold1fold",
				"contribute1to1pers1ghost1minotaur1minotaur",
				"contribute1to1pers1ghost1timeline1timeline",
				"contribute1to1pers1ghoul1day1day",
				"contribute1to1pers1ghoul1fold1fold",
				"contribute1to1pers1ghoul1minotaur1minotaur",
				"contribute1to1pers1ghoul1timeline1timeline",
				"contribute1to1pers1pers1day1day",
				"contribute1to1pers1pers1fold1fold",
				"contribute1to1pers1pers1minotaur1minotaur",
				"contribute1to1pers1pers1timeline1timeline"
			],
			"postsolo": [
				"contribute1to1ghost1day1day",
				"contribute1to1ghost1fold1fold",
				"contribute1to1ghost1minotaur1minotaur",
				"contribute1to1ghost1timeline1timeline",
				"contribute1to1ghoul1day1day",
				"contribute1to1ghoul1fold1fold",
				"contribute1to1ghoul1minotaur1minotaur",
				"contribute1to1ghoul1timeline1timeline",
				"contribute1to1pers1day1day",
				"contribute1to1pers1fold1fold",
				"contribute1to1pers1minotaur1minotaur",
				"contribute1to1pers1timeline1timeline"
			],
			"primix": [
				"contribute1to1ghost1ghost1day1day",
				"contribute1to1ghost1ghost1fold1fold",
				"contribute1to1ghost1ghost1minotaur1minotaur",
				"contribute1to1ghost1ghost1timeline1timeline",
				"contribute1to1ghost1ghoul1day1day",
				"contribute1to1ghost1ghoul1fold1fold",
				"contribute1to1ghost1ghoul1minotaur1minotaur",
				"contribute1to1ghost1ghoul1timeline1timeline",
				"contribute1to1ghost1pers1day1day",
				"contribute1to1ghost1pers1fold1fold",
				"contribute1to1ghost1pers1minotaur1minotaur",
				"contribute1to1ghost1pers1timeline1timeline",
				"contribute1to1ghoul1ghost1day1day",
				"contribute1to1ghoul1ghost1fold1fold",
				"contribute1to1ghoul1ghost1minotaur1minotaur",
				"contribute1to1ghoul1ghost1timeline1timeline",
				"contribute1to1ghoul1ghoul1day1day",
				"contribute1to1ghoul1ghoul1fold1fold",
				"contribute1to1ghoul1ghoul1minotaur1minotaur",
				"contribute1to1ghoul1ghoul1timeline1timeline",
				"contribute1to1ghoul1pers1day1day",
				"contribute1to1ghoul1pers1fold1fold",
				"contribute1to1ghoul1pers1minotaur1minotaur",
				"contribute1to1ghoul1pers1timeline1timeline",
				"contribute1to1pers1ghost1day1day",
				"contribute1to1pers1ghost1fold1fold",
				"contribute1to1pers1ghost1minotaur1minotaur",
				"contribute1to1pers1ghost1timeline1timeline",
				"contribute1to1pers1ghoul1day1day",
				"contribute1to1pers1ghoul1fold1fold",
				"contribute1to1pers1ghoul1minotaur1minotaur",
				"contribute1to1pers1ghoul1timeline1timeline",
				"contribute1to1pers1pers1day1day",
				"contribute1to1pers1pers1fold1fold",
				"contribute1to1pers1pers1minotaur1minotaur",
				"contribute1to1pers1pers1timeline1timeline"
			],
			"prisolo": [
				"contribute1to1ghost1day1day",
				"contribute1to1ghost1fold1fold",
				"contribute1to1ghost1minotaur1minotaur",
				"contribute1to1ghost1timeline1timeline",
				"contribute1to1ghoul1day1day",
				"contribute1to1ghoul1fold1fold",
				"contribute1to1ghoul1minotaur1minotaur",
				"contribute1to1ghoul1timeline1timeline",
				"contribute1to1pers1day1day",
				"contribute1to1pers1fold1fold",
				"contribute1to1pers1minotaur1minotaur",
				"contribute1to1pers1timeline1timeline"
			]
		},
		"following/urlpaths" : {
			"postmix": [
				"contribute1to1ghost1ghost1day1day",
				"contribute1to1ghost1ghost1fold1fold",
				"contribute1to1ghost1ghost1minotaur1minotaur",
				"contribute1to1ghost1ghost1timeline1timeline",
				"contribute1to1ghost1ghoul1day1day",
				"contribute1to1ghost1ghoul1fold1fold",
				"contribute1to1ghost1ghoul1minotaur1minotaur",
				"contribute1to1ghost1ghoul1timeline1timeline",
				"contribute1to1ghost1pers1day1day",
				"contribute1to1ghost1pers1fold1fold",
				"contribute1to1ghost1pers1minotaur1minotaur",
				"contribute1to1ghost1pers1timeline1timeline",
				"contribute1to1ghoul1ghost1day1day",
				"contribute1to1ghoul1ghost1fold1fold",
				"contribute1to1ghoul1ghost1minotaur1minotaur",
				"contribute1to1ghoul1ghost1timeline1timeline",
				"contribute1to1ghoul1ghoul1day1day",
				"contribute1to1ghoul1ghoul1fold1fold",
				"contribute1to1ghoul1ghoul1minotaur1minotaur",
				"contribute1to1ghoul1ghoul1timeline1timeline",
				"contribute1to1ghoul1pers1day1day",
				"contribute1to1ghoul1pers1fold1fold",
				"contribute1to1ghoul1pers1minotaur1minotaur",
				"contribute1to1ghoul1pers1timeline1timeline",
				"contribute1to1pers1ghost1day1day",
				"contribute1to1pers1ghost1fold1fold",
				"contribute1to1pers1ghost1minotaur1minotaur",
				"contribute1to1pers1ghost1timeline1timeline",
				"contribute1to1pers1ghoul1day1day",
				"contribute1to1pers1ghoul1fold1fold",
				"contribute1to1pers1ghoul1minotaur1minotaur",
				"contribute1to1pers1ghoul1timeline1timeline",
				"contribute1to1pers1pers1day1day",
				"contribute1to1pers1pers1fold1fold",
				"contribute1to1pers1pers1minotaur1minotaur",
				"contribute1to1pers1pers1timeline1timeline"
			],
			"postsolo": [
				"contribute1to1ghost1day1day",
				"contribute1to1ghost1fold1fold",
				"contribute1to1ghost1minotaur1minotaur",
				"contribute1to1ghost1timeline1timeline",
				"contribute1to1ghoul1day1day",
				"contribute1to1ghoul1fold1fold",
				"contribute1to1ghoul1minotaur1minotaur",
				"contribute1to1ghoul1timeline1timeline",
				"contribute1to1pers1day1day",
				"contribute1to1pers1fold1fold",
				"contribute1to1pers1minotaur1minotaur",
				"contribute1to1pers1timeline1timeline"
			],
			"primix": [
				"contribute1to1ghost1ghost1day1day",
				"contribute1to1ghost1ghost1fold1fold",
				"contribute1to1ghost1ghost1minotaur1minotaur",
				"contribute1to1ghost1ghost1timeline1timeline",
				"contribute1to1ghost1ghoul1day1day",
				"contribute1to1ghost1ghoul1fold1fold",
				"contribute1to1ghost1ghoul1minotaur1minotaur",
				"contribute1to1ghost1ghoul1timeline1timeline",
				"contribute1to1ghost1pers1day1day",
				"contribute1to1ghost1pers1fold1fold",
				"contribute1to1ghost1pers1minotaur1minotaur",
				"contribute1to1ghost1pers1timeline1timeline",
				"contribute1to1ghoul1ghost1day1day",
				"contribute1to1ghoul1ghost1fold1fold",
				"contribute1to1ghoul1ghost1minotaur1minotaur",
				"contribute1to1ghoul1ghost1timeline1timeline",
				"contribute1to1ghoul1ghoul1day1day",
				"contribute1to1ghoul1ghoul1fold1fold",
				"contribute1to1ghoul1ghoul1minotaur1minotaur",
				"contribute1to1ghoul1ghoul1timeline1timeline",
				"contribute1to1ghoul1pers1day1day",
				"contribute1to1ghoul1pers1fold1fold",
				"contribute1to1ghoul1pers1minotaur1minotaur",
				"contribute1to1ghoul1pers1timeline1timeline",
				"contribute1to1pers1ghost1day1day",
				"contribute1to1pers1ghost1fold1fold",
				"contribute1to1pers1ghost1minotaur1minotaur",
				"contribute1to1pers1ghost1timeline1timeline",
				"contribute1to1pers1ghoul1day1day",
				"contribute1to1pers1ghoul1fold1fold",
				"contribute1to1pers1ghoul1minotaur1minotaur",
				"contribute1to1pers1ghoul1timeline1timeline",
				"contribute1to1pers1pers1day1day",
				"contribute1to1pers1pers1fold1fold",
				"contribute1to1pers1pers1minotaur1minotaur",
				"contribute1to1pers1pers1timeline1timeline"
			],
			"prisolo": [
				"contribute1to1ghost1day1day",
				"contribute1to1ghost1fold1fold",
				"contribute1to1ghost1minotaur1minotaur",
				"contribute1to1ghost1timeline1timeline",
				"contribute1to1ghoul1day1day",
				"contribute1to1ghoul1fold1fold",
				"contribute1to1ghoul1minotaur1minotaur",
				"contribute1to1ghoul1timeline1timeline",
				"contribute1to1pers1day1day",
				"contribute1to1pers1fold1fold",
				"contribute1to1pers1minotaur1minotaur",
				"contribute1to1pers1timeline1timeline"
			]
		}
	}
}
