import { Injectable, inject } from '@angular/core';
import { Ancestry } from '../models/ancestry';
import { SSSAccountService } from './sss-account.service';
import { SSSNodeService } from './sss-node.service';
import { SSSTabService } from './sss-tab.service';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from '../ngrx/reducers';
import { PushActions } from '../ngrx/actions/beacon.actions';
import { ComandeerTab, SubstitutePointer } from '../ngrx/actions/tab.actions';
import { Trickle } from '../models/trickle';
import { Tab, Pointer } from '../models';
import { SSSMonthService } from './sss-month.service';
import { merge } from 'immutable';

@Injectable({
  providedIn: 'root'
})
export class SSSAncestryService {

	private sssAccountService = inject(SSSAccountService);
	private sssNodeService = inject(SSSNodeService);
	private store = inject(Store<AppState>);
	private sssTabService = inject(SSSTabService);
	private sssMonthService = inject(SSSMonthService);
	
	comandeerAncestry(ancestry: Ancestry): Ancestry {

		ancestry = _.cloneDeep(ancestry);

		const username = this.sssAccountService.getUser()._id;

		ancestry.pointer._id = this.sssNodeService.comandeerId(username, ancestry.pointer._id);

		ancestry = this.comandeerParents(ancestry);

		return ancestry;
	}

	comandeerParents(ancestry: Ancestry): Ancestry {

		ancestry = _.cloneDeep(ancestry);

		const username = this.sssAccountService.getUser()._id;

		if( ancestry.parentTabid ) 	{ ancestry.parentTabid = this.sssTabService.comandeerId(username, ancestry.parentTabid); }

		if( ancestry.parentNodeid ) { ancestry.parentNodeid = this.sssNodeService.comandeerId(username, ancestry.parentNodeid); }

		return ancestry;
	}

	generateTricklePath(earlyAncestor: Ancestry, parentTabid: string, idx: number): Trickle[] {

		const alreadyComandeered = this.sssTabService.hasBeenComandeered(parentTabid);

		const nextTier = [ new Trickle(parentTabid, !!earlyAncestor.pointer.pagination[idx || 0].ghost ) ];

		return alreadyComandeered ? nextTier : [ ...earlyAncestor.trickle_path, ...nextTier ];
	}

	traverse(ancestry: Ancestry, bubbleUp: Function): void {
		if( !this.sssTabService.isLeaf(ancestry.pointer) ) {

			this.store.dispatch(PushActions( { payload: bubbleUp( ancestry ) } ) );

		} else { // comandeer the immediate child tab

			const payload = ancestry.pointer.pagination.reduce((accum, pag, idx) => {
				return [...accum, ComandeerTab( { payload: this.sssTabService.deriveTabidFromPointer(ancestry.pointer, idx) }) ];
			}, ancestry.parentTabid ? [ SubstitutePointer( { payload: { ...ancestry, id: ancestry.parentTabid, comandeer: true } } ) ] : []);

			this.store.dispatch(PushActions( { payload } ) );
		}
	}

	traverseDay(tabid: string, ancestry): void {

		const payload = [
			ComandeerTab( { payload: tabid }),
			SubstitutePointer( { payload: { ...ancestry, id: ancestry.parentTabid, comandeer: true } } ),
		]

		this.store.dispatch( PushActions( { payload } ) ); 
	}

	getAncestryList(tab: Tab, shouldComandeer: boolean, ancestryList: Ancestry[], ancestry: Ancestry, pagIdx: number, override: Pointer = {}): Ancestry[] {

		const hash = (ancestryList || []).reduce( (accum, ancestry) => ({ ...accum, [ancestry.pointer.id]: ancestry, }), {} );

		return tab.inventory.map((pointer: Pointer) => {

			pointer = {...pointer, ...override};

			const same = JSON.stringify(pointer) == JSON.stringify(hash[pointer.id]?.pointer);

			return same ? shouldComandeer ? this.comandeerParents(hash[pointer.id]) : hash[pointer.id]
						: new Ancestry( _.cloneDeep(pointer), tab._id, ancestry.pointer._id, this.generateTricklePath(ancestry, tab._id, pagIdx) );
		});
	}

	getAncestryHash(tab: Tab, ancestry: Ancestry, pagIdx: number) {
		return tab.inventory.reduce((acc, val: Pointer) => {

			let dateString = this.sssMonthService.createDateString(new Date(val.currentdate));

			val = merge(val,{currenttab: "month-cell"});
			
			acc[dateString] = this.getAncestryForPointer(val, tab, ancestry, pagIdx);

			return acc;
		}, {});
	}

	getAncestryForPointer(pointer, tab: Tab, ancestry: Ancestry, pagIdx: number) {
		return new Ancestry(_.cloneDeep(pointer), tab._id, ancestry.pointer._id, this.generateTricklePath(ancestry, tab._id, pagIdx));
	}
}
