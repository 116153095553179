export class Pagination {

	serial 	 : string;
	ghost	?: string;
	ghoul	?: string;

	constructor(serial: string) {
		this.serial = serial;
	}
}
