import { AfterContentInit, Component, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject, takeUntil, tap } from 'rxjs';
import { AppState } from 'src/app/ngrx/reducers';
import { getListeners, getNodes, getTabs } from 'src/app/ngrx/selectors/graph.selector';
import { SSSApiService } from 'src/app/services/sss-api.service';
import { SSSCookieService } from 'src/app/services/sss-cookie.service';
import { SSSLocalService } from 'src/app/services/sss-local.service';
import { SSSSelectorService } from 'src/app/services/sss-selector.service';
import { MovieParserService } from '../movie-parser.service';
import * as options from './options';

@Component({
	selector: 'app-dev-footer',
	template: `
		<div class="ui pointing navbar"
			[style.bottom]="verticalToggle ? null : 0"
			[style.top]="verticalToggle ? 0 : null"
			style="	position: fixed;
					left: 0;
					right: 0;
					height:100px;
					background: white;
					border-top: 6px solid #888;
					box-shadow: 0 0 20px 16px #888;">
			<div style="width: 100%; display: flex; height: 30px;overflow: hidden;">
				<div>
					<img src='http://seasidesyndication.com/images/mysyllabi_logo.png'
						id="delete_localstorage_debug"
						style="height:20px; margin-top: 11px; margin-left: 14px; cursor: pointer">
				</div>
				<div>
					<button (click)="flush()" id="delete_cookies_debug" class="item navbar-item" style="margin: 8px 10px 0;">
						flush both
					</button>
					<!-- <button (click)="flushLocalStorage()" class="item navbar-item" style="margin: 8px 10px 0;">
						flush localstorage
					</button>
					<button (click)="flushCookies()" class="item navbar-item" style="margin: 8px 10px 0;">
						flush cookies
					</button> -->
				</div>
				<div style="margin-top: 8px; margin-right: 14px; position: relative;">
					<input id="input_testtype_debug"
						type="text"
						placeholder="test data loading url path"
						name="testlabel"
						style="width: 400px; padding-right: 52px;"
						[(ngModel)]="testLabel">
					<button (click)="testLabel = ''; this.resetTestForm();"
							style="position: absolute;
									right: 30px;
									top: 5px;
									height: 16px;
									width: 22px;
									font-size: 9px;
									cursor: pointer">&#10006;</button>
					<button (click)="handleXClick()"
							style="position: absolute;
									right: 4px;
									top: 5px;
									height: 16px;
									width: 22px;
									font-size: 9px;
									cursor: pointer">&#10084;</button>
				</div>
				<div style="margin-top: 8px; margin-right: 14px;">
					<button id="load_testdata_debug" (click)="handlePromptTestData()" style="margin-right: 14px;">load test</button>
					<span *ngIf="testingLight == true" style="color:white; background:green; width:25px; display:inline-block; border-radius: 5px; font-size:9px; text-align: center; line-height: 25px; position: relative; top: -1px;">ON</span>
					<span *ngIf="!testingLight" style="color:white; background:red; width:25px; display:inline-block; border-radius: 5px; font-size:9px; text-align: center; line-height: 25px; position: relative; top: -1px;">OFF</span>
				</div>
				<div style="margin-top: 8px; margin-right: 14px;">
					<button id="close_testing_debug" (click)="toggleOffTesting()">close testing</button>
				</div>
				<span style="padding-top: 8px">
					| toggle panel: <input style="cursor: pointer" type="checkbox" [(ngModel)]="verticalToggle">
				</span>
			</div>
			<hr>
			<div style="width: 100%; display: flex; margin-left:20px; height: 30px; overflow: hidden;">
				<div style="margin-top: 8px; margin-right: 14px;">
					<span id="login_test_debug"
						(click)="handleTestLogin()"
						style="cursor:pointer;">test login</span>
				</div>
				<div style="margin-top: 8px; margin-right: 14px; display:inline-block;">
					<button (click)="displayMovieiParserTool = !displayMovieiParserTool">
						Movie Parser
					</button>
				</div>
				<div style="margin-top: 8px; margin-right: 14px; display:inline-block;">
					<button (click)="dumpNodesInMemory()" id="print_nodestore_debug">
						dump nodes
					</button>
				</div>
				<div style="margin-top: 8px; margin-right: 14px; display:inline-block;">
					<button (click)="dumpTabsInMemory()" id="print_tabstore_debug">
						dump tabs
					</button>
				</div>
				<div style="margin-top: 8px; margin-right: 14px; display:inline-block;">
					<button (click)="dumpListenersInMemory()" id="print_listenerstore_debug">
						dump listeners
					</button>
				</div>
				<div style="margin-top: 8px; margin-right: 14px; display:inline-block;">
					<button (click)="dumpSelectorsInMemory()" id="print_selectorstore_debug">
						dump selectors
					</button>
				</div>
			</div>
		</div>
		<div  *ngIf="displayMovieiParserTool"
				style="position: fixed;
						bottom: 50px;
						right: 158px;
						width: 830px;
						border: 6px solid #888;
						border-radius: 10px;
						background: white;
						height: 650px;
						z-index: 1000;">
			<div style="margin-right: 6px; margin-top: 6px;">
				<span style="font-weight: 800;
							text-align: left;
							width: 673px;
							margin-left: 10px;
							display: inline-block;">
					Movie Script Parser
				</span>
				<span (click)="triggerMovieParse()" style="margin-right:20px; cursor: pointer;">parse movie</span>
				<span (click)="displayMovieiParserTool = false; movieScriptText = '';"
					style="cursor: pointer; text-align: right;">
					&#10006;
				</span>
			</div>
			<div style="padding: 2px 10px 0">
				<textarea [(ngModel)]="movieScriptText"
							name="movieParser"
							style="height: 596px; width: 798px; resize: none;">
				</textarea>
			</div>
		</div>
		<div  *ngIf="displayTestUrlTool"
				style="position: fixed;
						bottom: 50px;
						left: 320px;
						width: 400px;
						border: 6px solid #888;
						border-radius: 10px;
						background: white;
						height: 170px;
						z-index: 1000;">
			<div style="margin-right: 6px; margin-top: 6px;">
				<span style="font-weight: 800;
							text-align: left;
							width: 348px;
							margin-left: 10px;
							display: inline-block;">
					Generate Test Data URL String
				</span>
				<span (click)="displayTestUrlTool = false;" style="cursor: pointer; text-align: right;">
					&#10006;
				</span>
			</div>
			<div style="padding: 2px 10px 0">
				<select [(ngModel)]="status"
						(ngModelChange)="onChange($event, selection)"
						style="margin-bottom: 6px; border-radius: 4px; height: 20px; width: 100%;">

					<option value="default" disabled selected style="text-align: center;">-- status --</option>
					<option *ngFor="let x of options.statusOptions" [value]="x" [selected]="status == x">{{x}}</option>
				</select>
				<select [(ngModel)]="approach"
						(ngModelChange)="onChange($event, selection)"
						style="margin-bottom: 6px; border-radius: 4px; height: 20px; width: 100%;">

					<option value="default" disabled selected style="text-align: center;">-- approach --</option>
					<option *ngFor="let x of options.aproachOptions" [value]="x" [selected]="approach == x">{{x}}</option>
				</select>
				<select [(ngModel)]="ingrediants"
						(ngModelChange)="onChange($event, selection)"
						style="margin-bottom: 6px; border-radius: 4px; height: 20px; width: 100%;">

				<option value="default" disabled selected style="text-align: center;">-- ingrediants --</option>
					<option *ngFor="let x of options.ingrediantsOptions" [value]="x" [selected]="ingrediants == x">{{x}}</option>
				</select>
				<select [(ngModel)]="test"
						(ngModelChange)="onChange($event, selection)"
						style="margin-bottom: 6px; border-radius: 4px; height: 20px; width: 100%;">

					<option value="default" disabled selected style="text-align: center;">-- test --</option>
					<option
						*ngFor="let x of testOptions"
						[value]="x"
						[selected]="test == x">{{x}}</option>
				</select>
			</div>
		</div>
	`,
	styles: [``]
})
export class AppDevFooterComponent implements AfterContentInit {

	private sssCookieService = inject(SSSCookieService);
	private sssLocalService = inject(SSSLocalService);
	private sssApiService = inject(SSSApiService);
	private sssSelectorService = inject(SSSSelectorService);
	private store = inject(Store<AppState>);
	private sssMovieService = inject(MovieParserService);

	private _destroy$ 						: Subject<boolean> = new Subject();
	public testLabel 						: string;
	public movieScriptText 					: string;
    public testingLight						: boolean;
	public displayTestUrlTool				: boolean;
	public displayMovieiParserTool 			: boolean;
	public verticalToggle 					: boolean;
	public status 							: string = "default";
	public approach 						: string = "default";
	public ingrediants 						: string = "default";
	public test 							: string = "default";
	public options;

	get testOptions(): string[] { 	return 	!options.testOptions[this.status] ||
											!options.testOptions[this.status][this.approach] ||
											!options.testOptions[this.status][this.approach][this.ingrediants]

											? []

											: options.testOptions[this.status][this.approach][this.ingrediants]; }

	ngAfterContentInit() 		{ 	this.options = options;
									this.testLabel = this.sssCookieService.getCookie("testing") || "";
									this.testingLight = this.sssCookieService.getCookie("testing") &&
														this.sssCookieService.getCookie("testing").length > 0; }

	flush() 					{ 	this.sssCookieService.flush();
									this.sssLocalService.flush(); }

	flushLocalStorage() 		{ 	this.sssLocalService.flush(); }

	flushCookies() 				{ 	this.sssCookieService.flush(); }

	handleXClick() 				{	this.displayTestUrlTool		= !this.displayTestUrlTool;
									this.resetTestForm(); }

	resetTestForm() 			{  	this.status 				= "default";
									this.approach 				= "default";
									this.ingrediants 			= "default";
									this.test 					= "default"; }

	onChange() 					{	if( this.status == "default" ) { this.testLabel = ""; }
									if( this.status != "default" ) { this.testLabel = `${this.status}/pagination/contribute/` }
									if( this.status != "default" && this.approach != "default" ) {
										this.testLabel = `${this.status}/pagination/contribute/${this.approach}/`; }
									if( this.status != "default" && this.approach != "default" && this.ingrediants != "default" ) {
										this.testLabel = `${this.status}/pagination/contribute/${this.approach}/${this.ingrediants}`; }
									if( this.status != "default" && this.approach != "default" && this.ingrediants != "default" && this.test != "default" ) {
										this.testLabel = `${this.status}/pagination/contribute/${this.approach}/${this.ingrediants}/${this.test}`; } }

    handlePromptTestData()    	{ 	window[ "testing" ] = null;
									this.sssApiService.feedTestData( this.testLabel || "alpha" ).subscribe(res => {

										this.sssCookieService.setCookie("client_date", String(new Date().setHours(12,0,0,0)), 6);
										this.sssCookieService.setCookie("testing", this.testLabel || "alpha", 6);
										this.sssCookieService.removeCookie("applicationNodeId");

										window[ "testing" ] 	= true;
										this.testingLight 		= window[ "testing" ];
										this.testLabel 			= this.sssCookieService.getCookie("testing");
										this.displayTestUrlTool = !this.displayTestUrlTool;

										this.resetTestForm();
									}); }

	triggerMovieParse() 		{ 	console.log("movie", this.sssMovieService.parseMovie(this.movieScriptText)); }

    toggleOffTesting()          { 	window[ "testing" ] = false;
									this.testingLight = window[ "testing" ];
									this.testLabel = "";
									this.sssCookieService.removeCookie("testing"); }


    handleTestLogin()           { 	/* const payload = { username : "TESTER", password : "Broadway$19" };
                                    const transaction = [ new auth.Login( payload ) ] */ } ;


    dumpNodesInMemory() 		{ 	this.store.pipe( 	takeUntil(this._destroy$), select(getNodes), 
														tap(nodeHash => console.log("NODES IN MEMORY: ", nodeHash ) ) )
									.subscribe( 		nodeHash => window[ "ngrxNodeStore" ] = nodeHash ) }

	dumpTabsInMemory()          { 	this.store.pipe( 	takeUntil(this._destroy$), select(getTabs), 
														tap(tabHash => console.log("TABS IN MEMORY: ", tabHash ) ) )
									.subscribe( 		tabHash => window[ "ngrxTabStore" ] = tabHash )
	}

	dumpListenersInMemory() 	{ 	this.store.pipe( 	takeUntil(this._destroy$), select(getListeners), 
									tap(listenerHash => console.log("lISTENERS IN MEMORY: ", listenerHash ) ) )
									.subscribe( 		listenerHash => window[ "ngrxListenerStore" ] = listenerHash )
	}

	dumpSelectorsInMemory() 	{ 	console.log("NODE HASH: ", this.sssSelectorService.nodeHash )
									console.log("SISTER TAB HASH: ", this.sssSelectorService.sisterTabHash )
	}
}
